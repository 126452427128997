import React from 'react';

export function useArrayUpdates(callback, array) {
  const [length, setLength] = React.useState(array.length);
  const [watch, setWatch] = React.useState(false);

  React.useEffect(() => {
    if (!watch || length === array.length) {
      return;
    }

    setWatch(false);
    setLength(array.length);
    callback();
  }, [watch, length, array.length]);

  return {
    startWatching() {
      setWatch(true);
    },
  };
}
