import React from 'react';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { InjectedTranslateProps, Trans, translate } from 'react-i18next';
import { Text } from 'wix-ui-tpa/Text';
import { IUsersJoinedActivity } from '../../../../../controllers/feed/store/types';
import {
  withSiteMembers,
  WithSiteMembers,
} from '../../../Context/withSiteMembers';
import { st, classes } from '../ActivityStatus.st.css';
import { memberWrapper } from '@wix/social-groups-api';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { UserJoinedModal } from '../../../modals/UsersJoinedModal';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../../Context/withTpaComponentsConfig';

interface UsersJoinedComponentProps
  extends UsersJoinedProps,
    WithSiteMembers,
    WithTpaComponentsConfigProps,
    InjectedTranslateProps {}

interface UsersJoinedProps extends IUsersJoinedActivity {}

interface UsersJoinedState {
  isUserJoinedModalOpened: boolean;
}

class UsersJoinedComponent extends React.Component<
  UsersJoinedComponentProps,
  UsersJoinedState
> {
  constructor(props) {
    super(props);
    this.state = {
      isUserJoinedModalOpened: false,
    };
  }

  render() {
    const { userIds } = this.props;

    if (userIds.length === 1) {
      return this.renderUserJoined();
    }

    if (userIds.length === 2) {
      return this.render2UsersJoined();
    }

    return this.renderUsersJoined();
  }

  private renderUserJoined() {
    const { t, mobile } = this.props;

    return (
      <Text className={st(classes.root, { mobile })}>
        {t('groups-web.activity-post.user.joined')}
      </Text>
    );
  }

  private render2UsersJoined() {
    const { t, siteMembersMap, openUserProfile, mobile } = this.props;
    const otherUsers = this.getOtherUsers();
    const anotherUser = memberWrapper(siteMembersMap[otherUsers[0]]);
    return (
      <Trans
        i18nKey="groups-web.activity-post.2users.joined"
        values={{
          userName: anotherUser.name.nick || t('groups-web.member.anonymous'),
        }}
        components={[
          <Text className={st(classes.root, { mobile })} key="0">
            text with opacity
          </Text>,
          <TextButton
            className={st(classes.link, { mobile })}
            key="1"
            onClick={() => openUserProfile(anotherUser.siteMemberId)}
          >
            user name
          </TextButton>,
        ]}
      />
    );
  }

  private renderUsersJoined() {
    const { userIds, siteMembersMap, mobile } = this.props;
    const { isUserJoinedModalOpened } = this.state;
    return (
      <>
        <Trans
          i18nKey="groups-web.activity-post.users.joined"
          values={{
            N: userIds.length - 1,
          }}
          components={[
            <Text className={st(classes.root, { mobile })} key="0">
              text with opacity
            </Text>,
            <TextButton
              className={st(classes.link, { mobile })}
              key="1"
              onClick={this.openUserJoinedModal}
            >
              N others
            </TextButton>,
          ]}
        />
        <UserJoinedModal
          members={userIds.map((id) => memberWrapper(siteMembersMap[id]))}
          isOpen={isUserJoinedModalOpened}
          onRequestClose={this.closeUserJoinedModal}
        />
      </>
    );
  }

  private getOtherUsers() {
    const { userIds, authorUserId } = this.props;
    return userIds.filter((id) => id !== authorUserId);
  }

  private readonly openUserJoinedModal = () => {
    this.setState({ isUserJoinedModalOpened: true });
  };

  private readonly closeUserJoinedModal = () => {
    this.setState({ isUserJoinedModalOpened: false });
  };
}

const enhance = compose(translate(), withSiteMembers, withTpaComponentsConfig);

export const UsersJoined = enhance(UsersJoinedComponent) as React.ComponentType<
  UsersJoinedProps
>;
UsersJoined.displayName = 'UsersJoined';
