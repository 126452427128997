import * as React from 'react';
import { getUrlWithQuery } from './getUrlWithQuery';

interface NextPageSeoProps {
  url: string;
  query?: { [key: string]: string };
}

export const NextPageSeo: React.FunctionComponent<NextPageSeoProps> = (
  props: NextPageSeoProps,
) => {
  const { url, query } = props;
  if (!url) {
    return null;
  }
  return <a href={getUrlWithQuery(url, query)}></a>;
};

NextPageSeo.displayName = 'NextPageSeo';
