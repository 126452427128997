import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
// API
import {
  isAdmin,
  isJoined,
  isMember,
  isOwner,
  isNotMember,
} from '@wix/social-groups-api/dist/src/model/Member/MemberRole';
import { isGroupSecret } from '@wix/social-groups-api/dist/src/model/Group/GroupPrivacy';
import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';
// COMMON
import { SideDrawer } from '@wix/social-groups-common/dist/src/components/SideDrawer';
import { Spinner } from '@wix/social-groups-common/dist/src/components/Spinner';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  BIUserEntry,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';

import { ThreeDots } from '../../ThreeDots/ThreeDots';
import { ActionsBuilder, ActionsOnClickMap } from './ActionsBuilder';
import { GroupAction } from './GroupAction';
import { DeleteDialog } from '../Dialogs/DeleteDialog';
import { WithGroup, WithGroupProps } from '../Context/GroupContext';
import {
  WithGroupActionProps,
  WithGroupActions,
} from '../Context/GroupActionsContext';
import {
  MembershipChangeAction,
  withMembershipChangeAction,
} from '../Context/MembershipAction';
// LAZY
const NotificationSettings = React.lazy(() =>
  import(
    /* webpackChunkName: "NotificationSettings" */ '../NotificationSettings/NotificationSettings'
  ),
);
const EditGroupSettings = React.lazy(() =>
  import(
    /* webpackChunkName: "GroupSettings" */ '../GroupSettings/GroupSettings'
  ),
);

interface GroupActionsState {
  isActionsMenuOpened: boolean;
  openDialog: GroupAction;
}

interface GroupActionsProps {
  iconClassName?: string;
  onShare();
}

interface GroupActionsComponentProps
  extends WithGroupProps,
    InjectedTranslateProps,
    WithBiLoggerProps,
    WithGroupActionProps,
    MembershipChangeAction,
    GroupActionsProps {}

export class GroupActionsComponent extends React.Component<
  GroupActionsComponentProps,
  GroupActionsState
> {
  state: GroupActionsState = {
    openDialog: null,
    isActionsMenuOpened: false,
  };
  private readonly actionsOnClickMap: ActionsOnClickMap;

  constructor(props: Readonly<GroupActionsComponentProps>) {
    super(props);
    this.actionsOnClickMap = {
      [GroupAction.DELETE]: this.handleDeleteClick,
      [GroupAction.GROUP_SETTINGS]: this.openGroupSettingsDialog,
      [GroupAction.LEAVE]: this.handleLeaveClick,
      [GroupAction.SHARE]: props.onShare,
      [GroupAction.NOTIFICATION_SETTINGS]: this.openNotificationSettings,
    };
  }

  render() {
    const { group, iconClassName } = this.props;
    const groupWrapper = new GroupWrapper(group);
    const menuItems = this.getMenuItems();
    if (isNotMember(group) || menuItems.length === 0) {
      return null;
    }
    return (
      <>
        <ThreeDots
          onClose={this.closeActionsMenu}
          isOpen={this.state.isActionsMenuOpened}
          onOpen={this.openActionsMenu}
          items={menuItems}
          iconClassName={iconClassName}
        />
        {this.renderDeleteDialog(groupWrapper)}
        {this.renderDrawer()}
      </>
    );
  }

  private readonly handleDeleteClick = () => {
    this.sendDeleteGroupBIEvent('menu');
    this.openDeleteDialog();
  };

  private readonly handleLeaveClick = () => {
    this.props.changeMembership('menu');
  };

  private readonly closeActionsMenu = () => {
    this.setState({ isActionsMenuOpened: false });
  };

  private readonly openActionsMenu = () => {
    this.setState({ isActionsMenuOpened: true });
  };

  private getNonMemberActions() {
    const { t } = this.props;
    // const builder = new ActionsBuilder(t, this.actionsOnClickMap);
    // disable for now until it would be implemented
    // initial request https://wix.slack.com/archives/CDE9J122H/p1580934558016900
    // builder.addAction(GroupAction.REPORT);

    //    return builder.build();
    return [];
  }
  private getMemberActions() {
    const { t, group } = this.props;
    const builder = new ActionsBuilder(t, this.actionsOnClickMap);
    builder
      .addAction(GroupAction.NOTIFICATION_SETTINGS)
      .addAction(!isGroupSecret(group) ? GroupAction.SHARE : null)
      .addAction(GroupAction.LEAVE);
    return builder.build();
  }

  private getAdminActions() {
    const { t, group, currentMember } = this.props;
    const builder = new ActionsBuilder(t, this.actionsOnClickMap);
    builder
      .addAction(GroupAction.NOTIFICATION_SETTINGS)
      .addAction(GroupAction.GROUP_SETTINGS)
      .addAction(!isGroupSecret(group) ? GroupAction.SHARE : null);

    if (!isOwner(group, currentMember)) {
      builder.addAction(GroupAction.LEAVE);
    }
    builder.addAction(GroupAction.DELETE);
    return builder.build();
  }

  private getMenuItems() {
    const { group } = this.props;
    // Admin
    if (isAdmin(group)) {
      return this.getAdminActions();
    }
    // Member
    if (isMember(group) && isJoined(group)) {
      return this.getMemberActions();
    }
    return this.getNonMemberActions();
  }

  private readonly openNotificationSettings = () => {
    const { biLogger, group } = this.props;
    tryToCallBi(async () => {
      await biLogger.groupActionClick({
        action: 'notification_settings',
        group_id: group.groupId,
        origin: 'menu',
        userEntry: BIUserEntry.SITE,
      });
    });
    this.setState({ openDialog: GroupAction.NOTIFICATION_SETTINGS });
  };

  private readonly openGroupSettingsDialog = () => {
    tryToCallBi(async () => {
      await this.props.biLogger.groupActionClick({
        group_id: this.props.group.groupId,
        action: 'group_settings',
        origin: 'menu',
        userEntry: BIUserEntry.SITE,
      });
    });
    this.setState({ openDialog: GroupAction.GROUP_SETTINGS });
  };

  private renderDeleteDialog(groupWrapper: GroupWrapper) {
    const { group } = this.props;
    return isAdmin(group) ? (
      <DeleteDialog
        isOpen={this.state.openDialog === GroupAction.DELETE}
        onRequestClose={this.closeDialog}
        groupTitle={groupWrapper.getTitle()}
        onDelete={this.deleteGroup}
      />
    ) : null;
  }

  private readonly closeDialog = () => {
    this.setState({ openDialog: null });
  };

  private readonly openDeleteDialog = () => {
    this.setState({ openDialog: GroupAction.DELETE });
  };

  private readonly deleteGroup = () => {
    try {
      this.sendDeleteGroupBIEvent('modal_dialog_button');
      this.props.deleteGroup(this.props.group.groupId);
    } catch (e) {
      console.error('Delete Group failed');
    }
  };

  private renderDrawer() {
    const { openDialog } = this.state;
    const isOpen =
      openDialog === GroupAction.NOTIFICATION_SETTINGS ||
      openDialog === GroupAction.GROUP_SETTINGS;
    return (
      <SideDrawer isOpen={isOpen} onRequestClose={this.closeDialog}>
        {this.renderNotificationSettings()}
        {this.renderGroupSettings()}
      </SideDrawer>
    );
  }

  private renderGroupSettings() {
    const { group } = this.props;
    const { openDialog } = this.state;
    const render = isJoined(group) && openDialog === GroupAction.GROUP_SETTINGS;
    return render ? (
      <React.Suspense fallback={<Spinner />}>
        <EditGroupSettings onCancel={this.closeDialog} />
      </React.Suspense>
    ) : null;
  }

  private renderNotificationSettings() {
    const { group } = this.props;
    const { openDialog } = this.state;
    return isJoined(group) &&
      openDialog === GroupAction.NOTIFICATION_SETTINGS ? (
      <React.Suspense fallback={<Spinner />}>
        <NotificationSettings
          groupId={group.groupId}
          onCancel={this.closeDialog}
        />
      </React.Suspense>
    ) : null;
  }

  private sendDeleteGroupBIEvent(biOrigin: string) {
    const { biLogger, group } = this.props;
    tryToCallBi(async () => {
      await biLogger.groupActionClick({
        action: 'delete',
        group_id: group.groupId,
        origin: biOrigin,
        userEntry: BIUserEntry.SITE,
      });
    });
  }
}
const enhance = compose(
  translate(),
  WithGroup,
  WithGroupActions,
  withMembershipChangeAction,
  withBiLogger,
);
export const GroupActions = enhance(
  GroupActionsComponent,
) as React.ComponentType<GroupActionsProps>;
