import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';

import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  BIUserEntry,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';
import { RawDraftContentState } from '@wix/social-groups-common/dist/src/components/ContentEditor/types';

import { isAdmin } from '@wix/social-groups-api/dist/src/model/Member/MemberRole';
import { isGroupSecret } from '@wix/social-groups-api/dist/src/model/Group/GroupPrivacy';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import { WithGroup, WithGroupProps } from '../../Context/GroupContext';
import { NewPostModal } from '../../modals/NewPostModal';
import { DeletePostModal } from '../../modals/DeletePostModal';

import { ThreeDots } from '../../../ThreeDots/ThreeDots';

interface PostActionsProps {
  itemId: string;
  topicId: string;
  contentState: RawDraftContentState<any>;

  isPinnedPost: boolean;
  isActivityPost: boolean;
  isFollowedPost: boolean;
  showShareButton: boolean;

  deletePost(): void;

  pinPost(): void;

  unpinPost(): void;

  followPost(): void;

  unfollowPost(): void;

  updatePost(content: RawDraftContentState<any>, attachments?): void;

  postAuthor: ApiTypes.v1.GroupMemberResponse;

  onShare();

  iconClassName?: string;
}

interface PostActionsState {
  isActionsMenuOpened: boolean;
  isDeletePostModalOpened: boolean;
  isShareDialogOpen: boolean;
  inEditMode: boolean;
}

type Props = PostActionsProps &
  InjectedTranslateProps &
  WithGroupProps &
  WithBiLoggerProps;

class PostActionsComponent extends React.Component<Props, PostActionsState> {
  private readonly biParams;
  constructor(props) {
    super(props);
    this.state = {
      isActionsMenuOpened: false,
      isDeletePostModalOpened: false,
      inEditMode: false,
      isShareDialogOpen: false,
    };

    this.biParams = {
      group_id: this.props.group.groupId,
      post_id: this.props.itemId,
      origin: 'three_dots_menu',
      userEntry: BIUserEntry.SITE,
    };
  }

  closeActionsMenu = () => {
    if (this.state.isActionsMenuOpened) {
      return this.setState({ isActionsMenuOpened: false });
    }
  };
  toggleActionsMenu = () =>
    this.setState({ isActionsMenuOpened: !this.state.isActionsMenuOpened });

  openDeletePostPopup = () =>
    this.setState({
      isDeletePostModalOpened: true,
      isActionsMenuOpened: false,
    });
  closeDeletePostModal = () =>
    this.setState({ isDeletePostModalOpened: false });

  openEditMode = () => {
    const { biLogger } = this.props;
    tryToCallBi(async () => {
      await biLogger.postActionClick({
        action: 'edit',
        ...this.biParams,
      });
    });
    this.setState({ inEditMode: true, isActionsMenuOpened: false });
  };

  closeEditMode = () => this.setState({ inEditMode: false });

  handlePinUnpinPost = () => {
    const { pinPost, unpinPost, isPinnedPost, biLogger } = this.props;
    tryToCallBi(async () => {
      await biLogger.postActionClick({
        action: isPinnedPost ? 'unpin' : 'pin',
        ...this.biParams,
      });
    });
    isPinnedPost ? unpinPost() : pinPost();
    this.closeActionsMenu();
  };

  handleDeletePost = () => {
    const { biLogger } = this.props;
    tryToCallBi(async () => {
      await biLogger.postActionClick({
        action: 'delete',
        ...this.biParams,
      });
    });
    this.props.deletePost();
    this.closeDeletePostModal();
  };

  handleUpdatePost = (content: RawDraftContentState<any>, topicId?) => {
    const { biLogger, group, itemId } = this.props;
    this.props.updatePost(content, topicId);
    this.closeEditMode();

    if (topicId) {
      biLogger.groupFeedTopicsAddTopicToPost({
        groupId: group.groupId,
        userEntry: BIUserEntry.SITE,
        origin: 'post_to_the_topic',
        topicName: topicId,
        postId: itemId,
      });
    }
  };

  handleFollowUnfollowPost = () => {
    const { followPost, unfollowPost, isFollowedPost, biLogger } = this.props;
    tryToCallBi(async () => {
      await biLogger.postActionClick({
        action: isFollowedPost ? 'unfollow' : 'follow',
        ...this.biParams,
      });
    });
    isFollowedPost ? unfollowPost() : followPost();
    this.closeActionsMenu();
  };

  handleShareClick = () => {
    const { biLogger, onShare } = this.props;
    tryToCallBi(async () => {
      await biLogger.postActionClick({
        action: 'share',
        ...this.biParams,
      });
    });
    onShare();
  };

  renderMenuItems = () => {
    const {
      t,
      postAuthor,
      currentMember,
      group,
      isPinnedPost,
      isFollowedPost,
      isActivityPost,
      showShareButton,
    } = this.props;
    const items = [];

    const isMyPost = currentMember.siteMemberId === postAuthor.siteMemberId;
    const canDeletePost = isAdmin(group) || isMyPost;
    const canPinUnpinPost = isAdmin(group);
    const canFollow = true;
    const canEditPost = isMyPost && !isActivityPost;
    const canSharePost = showShareButton && !isGroupSecret(group);

    canPinUnpinPost &&
      items.push({
        onClick: this.handlePinUnpinPost,
        content: t(
          isPinnedPost
            ? 'groups-web.discussion.feed.post-actions.unpin-post'
            : 'groups-web.discussion.feed.post-actions.pin-post',
        ),
      });

    canFollow &&
      items.push({
        onClick: this.handleFollowUnfollowPost,
        content: t(
          isFollowedPost
            ? 'groups-web.discussion.feed.post-actions.following'
            : 'groups-web.discussion.feed.post-actions.follow',
        ),
      });

    canSharePost &&
      items.push({
        onClick: this.handleShareClick,
        content: t('groups-web.discussion.feed.post-actions.share'),
      });

    canEditPost &&
      items.push({
        onClick: this.openEditMode,
        content: t('groups-web.discussion.feed.post-actions.edit'),
      });

    canDeletePost &&
      items.push({
        onClick: this.openDeletePostPopup,
        content: t('groups-web.discussion.feed.post-actions.delete'),
      });

    return items;
  };

  render() {
    const { contentState, iconClassName, topicId, itemId } = this.props;
    const {
      isActionsMenuOpened,
      isDeletePostModalOpened,
      inEditMode,
    } = this.state;
    return (
      <div>
        <ThreeDots
          onClose={this.closeActionsMenu}
          isOpen={isActionsMenuOpened}
          onOpen={this.toggleActionsMenu}
          items={this.renderMenuItems()}
          iconClassName={iconClassName}
        />
        <DeletePostModal
          isOpen={isDeletePostModalOpened}
          onClose={this.closeDeletePostModal}
          onDelete={this.handleDeletePost}
        />
        {inEditMode ? (
          <NewPostModal
            isOpen
            isPostPublishing={false}
            onSubmit={this.handleUpdatePost}
            onVisibilityChange={this.closeEditMode}
            initialContentState={contentState}
            topicId={topicId}
            feedItemId={itemId}
          />
        ) : null}
      </div>
    );
  }
}

const enhance = compose(withBiLogger, WithGroup, translate());

export const PostActions = enhance(PostActionsComponent) as React.ComponentType<
  PostActionsProps
>;
