import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { classes } from '../Members.st.css';
import { Avatar, AvatarSize } from 'wix-ui-tpa/Avatar';
import { Text } from 'wix-ui-tpa/Text';
import { DATA_HOOKS } from '../dataHooks';
import { FollowButton } from '../../FollowButton';
import { RoleBadges } from '@wix/social-groups-common/dist/src/components';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { GroupMember } from '@wix/social-groups-api/dist/src/model/Member/GroupMember';
import { MemberActions } from './MemberActions';
import { AdminActions } from './AdminActions';

export interface MemberProps extends AdminActions {
  member: ApiTypes.v1.GroupMemberResponse;
  groupId: string;
  mobile: boolean;
  withMoreActions: boolean;
  isCurrentUser: boolean;
  isAdmin: boolean;
  openUserProfile(siteMemberId: string);
}
type Props = MemberProps & InjectedTranslateProps;

const MemberComponent = (props: Props) => {
  const {
    t,
    openUserProfile,
    mobile,
    withMoreActions,
    isCurrentUser,
    groupId,
    isAdmin,
    ...adminActions
  } = props;
  const member = new GroupMember(props.member);
  const memberId = member.getId();
  const imageUrl = member.getImageUrl();
  const name = member.getName(t('groups-web.member.anonymous'));
  const roles = member.getRoles() as ApiTypes.v1.RoleInGroup[];
  const relationship = member.getRelation();
  const isSiteAdmin = member.isSiteAdmin();
  const isGroupAdmin = member.isAdmin();
  const hasAnswers = member.hasAnswers();
  const navigateToUserProfile = () => openUserProfile(memberId);
  const showMemberActions =
    withMoreActions && !isCurrentUser && isAdmin && member.isJoined();

  return (
    <li
      key={memberId}
      className={classes.memberItem}
      onClick={navigateToUserProfile}
    >
      <Avatar
        size={mobile ? AvatarSize.small : AvatarSize.large}
        src={imageUrl}
        name={name}
      />
      <div className={classes.userInfo}>
        <div className={classes.nickAndRole}>
          <Text data-hook={DATA_HOOKS.username} className={classes.username}>
            {name}
          </Text>
          <RoleBadges
            roles={roles}
            relationship={relationship}
            className={classes.roleBadge}
          />
        </div>
      </div>
      {!mobile && withMoreActions ? (
        <>
          <div className={classes.mainButtons}>
            <FollowButton siteMemberId={memberId} />
          </div>
          <div className={classes.delimiter} />
        </>
      ) : null}
      {showMemberActions && (
        <MemberActions
          groupId={groupId}
          memberId={memberId}
          isSiteAdmin={isSiteAdmin}
          isGroupAdmin={isGroupAdmin}
          hasAnswers={hasAnswers}
          {...(adminActions as AdminActions)}
        />
      )}
    </li>
  );
};

export const Member = translate()(MemberComponent) as React.ComponentType<
  MemberProps
>;
