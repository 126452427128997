export const COMMENT_QUERY = 'commentId';

export function getCommentIdFromLocation() {
  if (typeof window === 'undefined') {
    return;
  }
  try {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(COMMENT_QUERY);
  } catch (e) {
    console.log(
      '[Comments.getCommentIdFromUrl] Failed to get CommentId from window.location.search',
    );
  }
}
