import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import {
  Button,
  Container,
  PendingMembers,
  withTPAConfig,
} from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { ModalV2FooterProps } from '@wix/social-groups-common/dist/src/components/Modal';
import { isGroupMember } from '@wix/social-groups-api/dist/src/model/Member/Member';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { GroupMember } from '@wix/social-groups-api/dist/src/model/Member/GroupMember';
import { PendingMember } from '@wix/social-groups-api/dist/src/model/Member/PendingMember';
import {
  MemberDTO,
  MemberResponse,
} from '@wix/social-groups-api/dist/src/model/Member/MemberDTO';
import { ItemsFilter } from '@wix/social-groups-api/dist/src/services/membersApi/MembersPublicApi';

import { TPAComponentsConfig } from 'wix-ui-tpa/TPAComponentsConfig';
import { classes, st } from './Members.st.css';
import { withSiteMembers, WithSiteMembers } from '../Context/withSiteMembers';
import { EmptyState } from '../EmptyState';
import {
  BIUserEntry,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';
import { RemoveMemberDialog } from '../Dialogs/RemoveMemberDialog';
import { ChangeAdminRoleDialog } from '../Dialogs/AddOrRemoveAdminRole';
import { DATA_HOOKS } from './dataHooks';
import { QuestionsAnswersModal } from '../modals/QuestionsAnswersModal';
import { AdminActions } from './Member/AdminActions';
import { PRIORITY } from 'wix-ui-tpa/Button';
import {
  withAppSettings,
  WithAppSettingsProps,
} from '../Context/withAppSettings';
import { WithGroup, WithGroupProps } from '../Context/GroupContext';
import { SortFilterSearch } from './SortFilterSearch/SortFilterSearch';
import {
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/wix-experiments-react';
import { MembersList } from './MembersList';

export interface MembersComponentProps {
  className?: string;
  appSettings?: WithAppSettingsProps['appSettings'];
  includeDeleted?: boolean;
  withMoreActions?: boolean; // TODO: why?
}

type InjectedProps = InjectedTranslateProps &
  Pick<WithGroupProps, 'group' | 'members' | 'currentMember' | 'promptLogin'> &
  Partial<WithSiteMembers> &
  TPAComponentsConfig &
  InjectedExperimentsProps;

enum DialogType {
  REMOVE_MEMBER = 'REMOVE_MEMBER',
  CHANGE_ADMIN_ROLE = 'CHANGE_ADMIN_ROLE',
  ANSWERS = 'ANSWERS',
}

interface MembersState {
  memberId: string | null;
  openDialogType: DialogType | null;
}

type Props = MembersComponentProps & InjectedProps & WithBiLoggerProps;

export class MembersComponent extends React.Component<Props, MembersState>
  implements AdminActions {
  static defaultProps = {
    appSettings: {
      membersDisplay: {},
    },
    withMoreActions: false,
    questionsAnswers: {},
  };

  readonly state: MembersState = {
    memberId: null,
    openDialogType: null,
  };

  componentDidMount(): void {
    const {
      getPendingMembers,
      group: { pendingMemberCount, groupId },
    } = this.props;
    if (pendingMemberCount && getPendingMembers) {
      getPendingMembers(groupId);
    }
  }

  render() {
    const {
      group,
      members,
      currentMember,
      currentSiteMember,
      t,
      openCurrentUserProfile,
      mobile,
      includeDeleted,
      withMoreActions,
      ...rest
    } = this.props;
    if (!currentSiteMember) {
      return this.renderMembersForAnonymous();
    }
    // TODO: remove filter from render function
    const groupMembers = includeDeleted
      ? members
      : members.filter(isGroupMember);

    return (
      <Container
        className={st(classes.root, {}, rest.className)}
        data-hook={DATA_HOOKS.root}
      >
        {this.renderPendingMembers()}
        {this.renderSortFilterSearch()}
        <MembersList
          members={groupMembers}
          withMoreActions={withMoreActions}
          onChangeAdmin={this.onChangeAdmin}
          onRemove={this.onRemove}
          onViewAnswers={this.onViewAnswers}
        />
        {this.renderActionModals()}
        {this.renderAnswers()}
      </Container>
    );
  }

  private renderSortFilterSearch() {
    if (
      this.props.experiments.enabled('specs.groups.MembersSortFilterSearch')
    ) {
      return <SortFilterSearch />;
    }
    return null;
  }

  onChangeAdmin = (memberId: string) => {
    this.openDialog(memberId, DialogType.CHANGE_ADMIN_ROLE);
  };

  onRemove = (memberId: string) => {
    this.openDialog(memberId, DialogType.REMOVE_MEMBER);
  };

  changeAdminRole = () => {
    const member = this.getGroupMember();
    if (!member) {
      return;
    }
    if (member.isAdmin() && !member.isSiteAdmin()) {
      return this.removeAdminRole();
    }
    this.addAdminRole();
  };

  openDialog(memberId: string, actionDialogType: DialogType) {
    this.setState({ memberId, openDialogType: actionDialogType });
  }

  readonly onViewAnswers = (memberId: string) => {
    const {
      biLogger,
      group: { groupId },
      listMembershipQuestionAnswers,
    } = this.props;
    tryToCallBi(async () => {
      await biLogger.groupMembershipQuestionsOwnerViewAnswers({
        groupId,
        memberUuid: memberId,
        origin: 'member_questions_screen',
      });
    });
    Promise.resolve(
      listMembershipQuestionAnswers(groupId, [memberId], ItemsFilter.BY_ID),
    ).catch((e) => console.log('Error in MembersComponent.onViewAnswers'));
    this.openDialog(memberId, DialogType.ANSWERS);
  };

  private renderPendingMembers() {
    const {
      pendingMembers,
      group: { pendingMemberCount },
      mobile,
    } = this.props;

    if (!pendingMemberCount) {
      return null;
    }

    const members = this.getPendingMembers();

    return (
      <>
        <PendingMembers
          onShowAnswersClick={(m) => this.onViewAnswers(m.siteMemberId)}
          pendingMembers={members}
          approveMember={(m) =>
            this.approvePendingMember('user_lvl_btn', m.siteMemberId)
          }
          rejectMember={(m) =>
            this.declinePendingMember('user_lvl_btn', m.siteMemberId)
          }
          loadMore={this.loadMorePendingMembers}
          total={pendingMemberCount}
          loading={!pendingMembers}
          mobile={mobile}
        />
      </>
    );
  }

  private renderAnswers() {
    const member = this.getGroupOrPendingMember();
    if (!member) {
      return null;
    }
    const { openDialogType } = this.state;
    const { mobile, questionsAnswers } = this.props;
    const id = member.getId();
    const qa = questionsAnswers[id];
    const footer = this.getAnswersFooter(member);
    return (
      <QuestionsAnswersModal
        userName={member.getName()}
        mobile={mobile}
        isOpen={openDialogType === DialogType.ANSWERS}
        onRequestClose={this.closeDialog}
        questionsAnswers={qa}
        {...footer}
      />
    );
  }

  private getAnswersFooter(
    member: MemberDTO<MemberResponse>,
  ): ModalV2FooterProps {
    const { t } = this.props;
    const memberId = member.getId();
    if (member.isPending()) {
      return {
        okButton: (
          <Button
            onClick={() =>
              this.approvePendingMember('member_questions_screen_btn', memberId)
            }
            priority={PRIORITY.primary}
          >
            {t('groups-web.members.pending.approve')}
          </Button>
        ),
        cancelButton: (
          <Button
            onClick={() =>
              this.declinePendingMember('member_questions_screen_btn', memberId)
            }
            priority={PRIORITY.secondary}
          >
            {t('groups-web.members.pending.decline')}
          </Button>
        ),
      };
    }
    return {
      okButton: (
        <Button onClick={this.closeDialog} priority={PRIORITY.primary}>
          {t('groups-web.close')}
        </Button>
      ),
    };
  }

  private getGroupMember() {
    const { memberId } = this.state;
    const { siteMembersMap } = this.props;
    if (!memberId || !siteMembersMap) {
      return null;
    }
    const member = siteMembersMap[memberId];
    return new GroupMember(member);
  }

  private getPendingMember() {
    const { memberId } = this.state;
    if (!memberId) {
      return null;
    }
    const member = this.getPendingMembers().find(
      (m) => m.siteMemberId === memberId,
    );

    return member ? new PendingMember(member) : null;
  }

  private getGroupOrPendingMember(): MemberDTO<MemberResponse> {
    return this.getPendingMember() || this.getGroupMember();
  }

  private getPendingMembers() {
    const { pendingMembers } = this.props;
    return (pendingMembers && pendingMembers.members) || [];
  }

  private readonly renderMembersForAnonymous = () => {
    const { t, promptLogin } = this.props;
    return (
      <EmptyState
        className={classes.emptyState}
        title={t('group-web.members.anonymous-state.title')}
        content={t('group-web.members.anonymous-state.description')}
        button={{
          label: t('group-web.members.anonymous-state.button'),
          onClick: () => promptLogin(),
        }}
      />
    );
  };

  private renderActionModals() {
    const { withMoreActions, t, siteMembersMap } = this.props;
    const { openDialogType, memberId } = this.state;
    const siteMember = memberId && siteMembersMap[memberId];
    const shouldRenderActionModals =
      withMoreActions && !!memberId && siteMember;
    if (!shouldRenderActionModals) {
      return null;
    }
    const member = new GroupMember(siteMember);
    const image = member.getImageUrl();
    const name = member.getName(t('groups-web.member.anonymous'));
    const isGroupAdmin = member.isAdmin();
    return (
      <>
        <RemoveMemberDialog
          isOpen={openDialogType === DialogType.REMOVE_MEMBER}
          memberFullName={name}
          onConfirmDeletion={this.confirmMemberRemoval}
          onRequestClose={this.closeDialog}
        />
        <ChangeAdminRoleDialog
          isOpen={openDialogType === DialogType.CHANGE_ADMIN_ROLE}
          name={name}
          image={image}
          isAdmin={isGroupAdmin}
          onConfirmClick={this.changeAdminRole}
          onRequestClose={this.closeDialog}
        />
      </>
    );
  }

  private readonly closeActionsMenu = () => {
    this.setState({ memberId: null });
  };

  private readonly closeDialog = () => {
    const { memberId, openDialogType } = this.state;
    if (!memberId && !openDialogType) {
      return;
    }

    this.setState({
      memberId: null,
      openDialogType: null,
    });
  };

  private readonly confirmMemberRemoval = () => {
    const {
      group: { groupId },
      removeMembersFromGroup,
      biLogger,
    } = this.props;
    const { memberId } = this.state;
    tryToCallBi(async () => {
      await biLogger.memberTabAdminActionDone({
        group_id: groupId,
        action: 'remove_member',
        site_member_id: memberId,
        origin: 'dialog_screen_btn_clk',
        userEntry: BIUserEntry.SITE,
      });
    });
    removeMembersFromGroup(groupId, [memberId]);
    this.closeDialog();
  };

  private addAdminRole() {
    const {
      group: { groupId },
      changeMemberRoleInGroup,
      biLogger,
    } = this.props;
    const { memberId } = this.state;
    tryToCallBi(async () => {
      await biLogger.memberTabAdminActionDone({
        group_id: groupId,
        action: 'set_admin',
        site_member_id: memberId,
        origin: 'dialog_screen_btn_clk',
        userEntry: BIUserEntry.SITE,
      });
    });
    changeMemberRoleInGroup(
      groupId,
      memberId,
      ApiTypes.v1.RoleInGroup.GROUP_ADMIN,
    );
    this.closeDialog();
  }

  private removeAdminRole() {
    const {
      group: { groupId },
      changeMemberRoleInGroup,
      biLogger,
    } = this.props;
    const { memberId } = this.state;
    tryToCallBi(async () => {
      await biLogger.memberTabAdminActionDone({
        group_id: groupId,
        action: 'remove_admin',
        site_member_id: memberId,
        origin: 'dialog_screen_btn_clk',
        userEntry: BIUserEntry.SITE,
      });
    });
    changeMemberRoleInGroup(
      groupId,
      memberId,
      ApiTypes.v1.RoleInGroup.GROUP_MEMBER,
    );
    this.closeDialog();
  }

  private readonly approvePendingMember = (
    origin: string,
    memberId: string,
  ) => {
    try {
      const {
        group: { groupId },
      } = this.props;

      tryToCallBi(async () => {
        await this.props.biLogger.groupsMemberRequestApproveDecline({
          type: 'approve',
          group_id: this.props.group.groupId,
          isAll: false,
          memberId,
          origin,
        } as any);
      });

      this.props.approvePendingMembersRequests(groupId, [memberId]);
      // TODO: loading state?
    } catch (e) {
      // TODO: handle with UI
      console.error('Approve Pending Members: FAIL');
    }
    this.closeDialog();
  };

  private readonly declinePendingMember = (
    origin: string,
    memberId: string,
  ) => {
    try {
      const {
        group: { groupId },
      } = this.props;

      tryToCallBi(async () => {
        await this.props.biLogger.groupsMemberRequestApproveDecline({
          type: 'decline',
          group_id: this.props.group.groupId,
          isAll: false,
          memberId,
          origin,
        } as any);
      });

      this.props.rejectPendingMembersRequests(groupId, [memberId]);
    } catch (e) {
      console.error('Reject Pending Members: FAIL');
    }
    this.closeDialog();
  };

  private readonly loadMorePendingMembers = () => {
    try {
      const {
        group: { groupId },
        pendingMembers: { cursorTokens },
        getPendingMembers,
      } = this.props;
      getPendingMembers(groupId, null, cursorTokens.next);
    } catch (e) {
      console.error('Load more pending members: FAIL');
    }
  };
}

const enhance = compose(
  translate(),
  WithGroup,
  withSiteMembers,
  withTPAConfig,
  withAppSettings,
  withBiLogger,
  withExperiments,
);

export const Members = enhance(MembersComponent) as React.ComponentType<
  MembersComponentProps
>;

export default Members;
