import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { EmptyState } from '../EmptyState';
import { CommonErrorProps } from './CommonErrorProps';

type Props = CommonErrorProps & InjectedTranslateProps;
export const Error500Component: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const { isOwner, action, t } = props;
  return (
    <EmptyState
      title={t('groups-web.discussion.error-500.title')}
      content={t(
        isOwner
          ? 'groups-web.discussion.error-500.content.owner'
          : 'groups-web.discussion.error-500.content.user',
      )}
      button={{
        label: t(
          isOwner
            ? 'groups-web.discussion.error-500.cta.owner'
            : 'groups-web.discussion.error-500.cta.user',
        ),
        onClick: () => {
          if (isOwner) {
            window.location.replace(
              'https://www.wix.com/support/conversations/category/contact',
            );
            return;
          }
          return action();
        },
      }}
    />
  );
};

Error500Component.displayName = 'Error500';

export const Error500 = translate()(Error500Component) as React.ComponentType<
  CommonErrorProps
>;
