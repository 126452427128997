import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import classnames from 'classnames';
import moment from 'moment'; // TODO: remove??
// WIX-UI-TPA
import { AvatarSize } from 'wix-ui-tpa/Avatar';
// COMMON
import {
  Box,
  Clock,
  Header,
  IconCard,
  UserCard,
} from '@wix/social-groups-common/dist/src/components';
// API
import { memberWrapper } from '@wix/social-groups-api/dist/src/model/Member/Member';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';

import { PrivacyMap } from '../../Privacy/PrivacyMap';
import { DATA_HOOKS } from '../dataHooks';
// STYLES
import { classes } from '../About.st.css';
import infoStyles from './GroupInfo.st.css';

interface GroupInfoProps {
  group: ApiTypes.v1.GroupResponse;
}

const getTimeFromCreateDate = (d: Date) => {
  return moment(d).from(Date.now());
};

const GroupInfoComponent = (props: GroupInfoProps & InjectedTranslateProps) => {
  const { t, group } = props;
  const g = new GroupWrapper(group);
  const privacy = g.getPrivacy();
  const createdAt = getTimeFromCreateDate(g.getCreatedDate());
  const { title, icon, label } = PrivacyMap[privacy];
  const { name, imageUrl } = memberWrapper(group.createdBy);
  return (
    <Box className={classnames(classes.root, infoStyles.classes.root)}>
      <div className={classes.headerWrapper}>
        <Header className={classes.header}>{t('groups-web.group-info')}</Header>
      </div>
      <div className={infoStyles.classes.wrapper}>
        <div className={infoStyles.classes.row}>
          <IconCard
            className={infoStyles.classes.card}
            icon={
              <div className={infoStyles.classes.groupTypeIcon}>{icon}</div>
            }
            title={t(title)}
            subtitle={t(label)}
          />
        </div>
        <div className={infoStyles.classes.row}>
          <IconCard
            className={infoStyles.classes.card}
            icon={<Clock />}
            title={`${t('groups-web.created')} ${createdAt}`}
          />
        </div>
        <div className={infoStyles.classes.createdByRow}>
          <UserCard
            className={infoStyles.classes.card}
            subtitle={`${t('groups-web.createdBy')}:`}
            name={name.nick || t('groups-web.member.anonymous')}
            pic={imageUrl}
            avatarSize={AvatarSize.small}
            dataHook={DATA_HOOKS.createdBy}
            reverse
          />
          {/* <ChatIcon className={infoStyles.chatIcon} /> */}
        </div>
      </div>
    </Box>
  );
};

export const GroupInfo = translate()(GroupInfoComponent) as React.ComponentType<
  GroupInfoProps
>;
