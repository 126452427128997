import React from 'react';
import { WixRicosViewer } from '@wix/ricos-viewer';
import { translate, InjectedTranslateProps } from 'react-i18next';
import {
  withExperiments,
  InjectedExperimentsProps,
} from '@wix/wix-experiments-react';
import { compose } from '@wix/social-groups-common/dist/src/compose';

import { AppDataContext } from '../Group/Context/AppData/AppData';

import { viewerPluginSelector } from './plugins/viewer-plugins';
import { PRESETS, RichContentViewerProps } from './types';

import './ContentEditor.global.css';
import { st, classes } from './ricos.st.css';
import { useHelpers } from './hooks/useHelpers';
import { PluginTypeParser } from '@wix/social-groups-common/dist/src/components/ContentEditor/plugins/plugin-type-parser/PluginTypeParser';
import { RawDraftContentState } from '@wix/social-groups-common/dist/src/components/ContentEditor/types';

import { createPreview } from './transformations';
import { useRicosTheme } from './useTheme';

export const _RichContentViewer: React.FC<
  RichContentViewerProps & InjectedTranslateProps & InjectedExperimentsProps
> = (props) => {
  const {
    preset,
    usage,
    className,
    handleUserValidationRequest,
    disablePreview,
    t,
    experiments,
  } = props;

  const content = props.content as RawDraftContentState<any>;

  const { instance, language, host } = React.useContext(AppDataContext);
  const helpers = useHelpers({
    handleUserValidationRequest,
  });
  const pluginSelector = viewerPluginSelector(preset, helpers);
  const [plugins, setPlugins] = React.useState(pluginSelector.plugins);
  const ricosThemeProps = useRicosTheme(
    host,
    !experiments.enabled('specs.groups.RicosTheme'),
  );

  const { pluginTypes, hasPolls } = React.useMemo(() => {
    const pluginTypeParser: PluginTypeParser = new PluginTypeParser();
    return {
      pluginTypes: pluginTypeParser.getContentTypes(content),
      hasPolls: pluginTypeParser.hasPolls(content),
    };
  }, [content]);

  const enablePreview =
    !(hasPolls || disablePreview) &&
    experiments.enabled('specs.groups.RicosPreview');

  const preview = React.useMemo(
    () => (enablePreview ? createPreview(t) : null),
    [enablePreview],
  );

  React.useEffect(() => {
    if (pluginTypes.length) {
      pluginSelector
        .loadPlugins(pluginTypes)
        .then((_plugins) => {
          if (_plugins) {
            setPlugins([]); // have no idea why but it doesn't work without it
            setPlugins(_plugins);
          }
        })
        .catch((e) => {
          console.log('[RichContentViewer] Error Loading Plugins');
          // TODO: report sentry
        });
    }
  }, [JSON.stringify(pluginTypes)]);

  return (
    <div className={st(classes.root, { mobile: props.isMobile }, className)}>
      <WixRicosViewer
        {...ricosThemeProps}
        {...props}
        key={plugins.length}
        instance={instance}
        plugins={plugins}
        locale={language}
        preview={preview}
        linkSettings={{ relValue: 'nofollow ugc', anchorTarget: '_blank' }}
        wixExperiments={experiments}
        biSettings={{
          consumer: 'Groups',
          platform: 'Livesite',
          usage,
        }}
      />
    </div>
  );
};

_RichContentViewer.defaultProps = {
  preset: PRESETS.POST,
  disablePreview: false,
};

const enhance = compose(translate(), withExperiments);

export const RichContentViewer = enhance(_RichContentViewer);
