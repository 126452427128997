import React from 'react';
import { Dialog } from '@wix/social-groups-common/dist/src/components/Dialog';
import { ModalProps } from '@wix/social-groups-common/dist/src/components/Modal';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { MembershipButton } from '../Membership';
import { WithGroup, WithGroupProps } from '../Context/GroupContext';

type Props = ModalProps & InjectedTranslateProps & WithGroupProps;

const JoinDialogComponent: React.FunctionComponent<Props> = (props) => {
  const { t, group, ...rest } = props;
  const joinTitle = t('groups-web.join.title');
  const joinText = t('groups-web.join.text', {
    group: group.details.title,
    interpolation: { escapeValue: false },
  });

  return (
    <Dialog
      title={joinTitle}
      caption={joinText}
      {...rest}
      buttons={<MembershipButton biOrigin="public_post_join_group_btn" />}
    />
  );
};

const enhance = compose(translate(), WithGroup);

export const JoinDialog = enhance(JoinDialogComponent) as React.ComponentType<
  ModalProps
>;
