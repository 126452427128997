import Url from 'url-parse';

// TODO: move to utils?
export function getUrlWithQuery(url: string, query: { [p: string]: string }) {
  const _url = new Url(url);
  _url.set('query', query);
  const { protocol } = Url.extractProtocol(url);
  if (!protocol) {
    const qs = query ? `?${Url.qs.stringify(query)}` : '';
    return `${url}${qs}`;
  }
  return _url.toString();
}
