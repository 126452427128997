import React, { useMemo } from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { translate, InjectedTranslateProps } from 'react-i18next';

import { Spinner } from '@wix/social-groups-common/dist/src/components';

import { EventsErrorBoundary } from './EventsErrorBoundary';
import { EventsContext } from '../Context/EventsContext';
import { EmptyState } from '../EmptyState';

import { sortByDateCreated } from '@wix/social-groups-api/dist/src/model/Events/helpers';

import { Event } from './Event';
import { classes } from './Events.st.css';

interface EventsProps {
  groupId: string;
}

type Props = EventsProps & InjectedTranslateProps;
const EventsComponent: React.FC<Props> = ({ t, groupId }) => {
  const { events, fetch, error } = React.useContext(EventsContext);

  React.useEffect(() => {
    fetch();
  }, []);

  const sortedEvents = useMemo(() => {
    if (!events) {
      return [];
    }

    return sortByDateCreated(events);
  }, [events]);

  if (error || (events && !events.length)) {
    return (
      <EmptyState
        title={t('groups-web.events.empty.title')}
        content={t('groups-web.events.empty.subtitle')}
      />
    );
  }

  if (!events) {
    return <Spinner offset="M" label={t('groups-web.loading')} />;
  }

  return (
    <div className={classes.root}>
      <Text tagName="h2" className={classes.title}>
        {t('groups-web.events.upcoming')}
      </Text>
      {sortedEvents.map((event, i) => (
        <Event
          key={event.id}
          event={event}
          last={i === events.length - 1}
          groupId={groupId}
        />
      ))}
    </div>
  );
};

export const Events = translate()(
  EventsErrorBoundary(EventsComponent),
) as React.ComponentType<EventsProps>;
